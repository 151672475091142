import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Moment from 'moment';
import 'moment/locale/pt-br';
import { endpointState } from '../../recoil/atoms';

import BitButton from '../../components/BitButton/BitButton';

import BitHeaderFull from '../../components/BitHeader/BitHeaderFull';
import BitPage from '../../components/BitPage/BitPage';
import BitInput from '../../components/BitInput/BitInput';
import BitLinkButton from '../../components/BitLinkButton/BitLinkButton';

const UpdateCommonArea: React.FC = () => {
	Moment.locale('pt-br');

	type typeResponseAxios = {
		[key: string]: any;
	};

	interface ParamTypes {
		idRegister: string;
	}

	const { idRegister } = useParams<ParamTypes>();

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointUpdate = `${urlEndpoint}/calendars/update/${idRegister}`;
	const urlEndpointGet = `${urlEndpoint}/calendars/${idRegister}`;

	const token = localStorage.getItem('token');

	const [development, setDevelopment] = useState({
		value: '',
		id: 0,
		required: true,
	});

	const [nome, setNome] = useState({
		value: '',
		required: true,
	});
	const [tempoDuracao, setTempoDuracao] = useState({
		value: '',
		required: true,
	});
	const [descricao, setDescricao] = useState({
		value: '',
		required: false,
	});
	const [horarioInicio, setHorarioInicio] = useState({
		value: '',
		required: true,
	});
	const [horarioFim, setHorarioFim] = useState({
		value: '',
		required: true,
	});
	const [dataInicio, setDataInicio] = useState({
		value: '',
		required: false,
	});
	const [dataFim, setDataFim] = useState({
		value: '',
		required: false,
	});
	const [politicaDeAgendamento, setPoliticaDeAgendamento] = useState<{
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}>({
		value: [
			{
				value: '0',
				label: 'Agendamento Livre',
				selected: true,
			},
			{
				value: '1',
				label:
					'Agendamento Unico (O usuário poderá agendar uma unica vez, até que o evento/agendamento dele aconteça)',
				selected: false,
			},
		],
		required: false,
	});

	const [showRequiredBallon, setShowRequiredBallon] = useState(false);

	const currentFields = document.querySelectorAll(
		'input, select, textarea, button',
	);

	const lockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.setAttribute('disabled', '');
		});
	};

	const unlockFields = (fields: NodeListOf<Element>) => {
		fields.forEach(function (elField) {
			elField.removeAttribute('disabled');
		});
	};

	useEffect(() => {
		axios
			.get(urlEndpointGet, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const startTime = response.data.data.start_time.split(':');
				const endTime = response.data.data.end_time.split(':');
				const duration = response.data.data.event_range.split(':');

				setNome({
					value: response.data.data.name,
					required: nome.required,
				});
				setDevelopment({
					value: response.data.data.development_id || '',
					id: response.data.data.development_id,
					required: development.required,
				});
				setTempoDuracao({
					value: `${duration[0]}:${duration[1]}`,
					required: tempoDuracao.required,
				});
				setHorarioInicio({
					value: `${startTime[0]}:${startTime[1]}`,
					required: horarioInicio.required,
				});
				setHorarioFim({
					value: `${endTime[0]}:${endTime[1]}`,
					required: horarioFim.required,
				});
				setDataInicio({
					value: response.data.data.start_date,
					required: dataInicio.required,
				});
				setDataFim({
					value: response.data.data.end_date,
					required: dataFim.required,
				});
				setPoliticaDeAgendamento({
					value: [
						{
							value: '0',
							label: 'Agendamento Livre',
							selected: !response.data.data.unique,
						},
						{
							value: '1',
							label:
								'Agendamento Unico (O usuário poderá agendar uma unica vez, até que o evento/agendamento dele aconteça)',
							selected: Boolean(response.data.data.unique),
						},
					],
					required: true,
				});
			});
	}, []);

	const handleCadastrar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setShowRequiredBallon(true);
		lockFields(currentFields);

		const politica = politicaDeAgendamento.value.filter(
			item => item.selected,
		)[0];

		axios
			.put(
				urlEndpointUpdate,
				{
					name: nome.value,
					short_description: descricao.value || 'Sem descrição',
					development_id: development.value,
					event_range: tempoDuracao.value,
					start_time: horarioInicio.value,
					end_time: horarioFim.value,
					open_after: dataInicio.value
						? Moment(dataInicio.value).format('YYYY-MM-DD')
						: null,
					close_before: dataFim.value
						? Moment(dataFim.value).format('YYYY-MM-DD')
						: null,
					unique: politica?.value || 0,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				},
			)
			.then(function (response: typeResponseAxios) {
				if (response.status > 200) {
					toast.success('Cadastro atualizado com sucesso!', {
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					});

					unlockFields(currentFields);
				}
			})
			.catch(function (error) {
				const errosServidor = error.response.data.error;
				toast.error(
					'Erro ao atualizar. Verifique os campos obrigatórios e tente novamente.',
					{
						position: 'bottom-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: false,
						progress: undefined,
					},
				);

				unlockFields(currentFields);
			});
	};

	return (
		<BitPage>
			<BitHeaderFull
				label={`Atualizar  / Área Comun`}
				icon={''}
				hasIcon={false}
			/>

			<div className="p-5 mb-4 bg-white rounded">
				<h4 className="font-bold mb-4">ATUALIZAR ÁREA COMUN</h4>

				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">1</span>
					DADOS ÁREA COMUN
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Nome"
							type="text"
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setNome({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={nome.value}
							required={nome.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Tempo de Agendamento (Horas)"
							type="maskedinput"
							mask={[/\d/, /\d/, ':', /\d/, /\d/]}
							placeholder="Digite..."
							classField="grupo-campo-novos-registros"
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setTempoDuracao({
									value: e.currentTarget.value,
									required: true,
								})
							}
							value={tempoDuracao.value}
							required={tempoDuracao.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Horário Abertura"
							type="maskedinput"
							mask={[/\d/, /\d/, ':', /\d/, /\d/]}
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setHorarioInicio({
									value: e.currentTarget.value,
									required: horarioInicio.required,
								})
							}
							value={horarioInicio.value}
							required={horarioInicio.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Horário Fechamento"
							type="maskedinput"
							mask={[/\d/, /\d/, ':', /\d/, /\d/]}
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setHorarioFim({
									value: e.currentTarget.value,
									required: horarioFim.required,
								})
							}
							value={horarioFim.value}
							required={horarioFim.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4 mb-10">
						<BitInput
							label="Data de Início"
							type="date"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDataInicio({
									value: e.currentTarget.value,
									required: dataInicio.required,
								})
							}
							value={dataInicio.value}
							required={dataInicio.required}
							showRequiredBallon={showRequiredBallon}
						/>
						<BitInput
							label="Data de Fim"
							type="date"
							placeholder="Digite..."
							classField=""
							onChange={(e: React.FormEvent<HTMLInputElement>) =>
								setDataFim({
									value: e.currentTarget.value,
									required: dataFim.required,
								})
							}
							value={dataFim.value}
							required={dataFim.required}
							showRequiredBallon={showRequiredBallon}
						/>
					</div>
				</div>
				<h4 className="font-bold mb-4">
					<span className="bolinha-verde-numero">2</span>
					Política de Agendamento
				</h4>

				<div className="bloco-campos-novo-cadastro">
					<div className="mb-4">
						<BitInput
							label="Política"
							type="select"
							multipleSelect={false}
							placeholder=""
							valuesSelect={politicaDeAgendamento.value}
							classField="grupo-campo-novos-registros"
							onChange={(
								e: React.FormEvent<HTMLSelectElement>,
							) => {
								const allOptions = Object.entries(
									e.currentTarget.options,
								);
								const newValuePoliticaAgendamento: {
									value: string | number;
									label: string;
									selected: boolean;
								}[] = [];

								allOptions.map(option => {
									if (option[1].value !== '') {
										newValuePoliticaAgendamento.push({
											value: option[1].value,
											label: option[1].text,
											selected: option[1].selected,
										});
									}
									return null;
								});

								setPoliticaDeAgendamento({
									value: newValuePoliticaAgendamento,
									required: true,
								});
							}}
						/>
					</div>
					<div className="text-right">
						<div className="inline-block mr-4">
							<BitLinkButton
								text={'VOLTAR'}
								buttonColor={'#3AABA2'}
								textColor={'#FFFFFF'}
								link={'/common-areas'}
							/>
						</div>
						<BitButton
							text={'FINALIZAR'}
							buttonColor={'#3AABA2'}
							textColor={'#FFFFFF'}
							type={'fit px-10'}
							classesBlockOption={'inline-block'}
							onClick={handleCadastrar}
						/>
					</div>
				</div>
				<ToastContainer
					position="bottom-right"
					autoClose={2000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</BitPage>
	);
};

export default UpdateCommonArea;
