import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import moment from 'moment';

import {
	Calendar,
	Scheduler,
	SchedulerExistingEvent,
	useArrayState,
} from '../../components/SchedulerBase';

import { endpointState } from '../../recoil/atoms';

interface Props {
	developmentId: string;
	calendarId?: string;
}
const colors: { [key: string]: string } = {
	'0': '#f1c40f',
	'1': '#e74c3c',
	'2': '#3498db',
	'3': '#e67e22',
	'4': '#2ecc71',
	'5': '#9b59b6',
	'6': '#f39c12',
	'7': '#95a5a6',
	'8': '#34495e',
	'9': '#16a085',
	'10': '#2c3e50',
	'11': '#2c3e50',
	'12': '#bdc3c7',
	'13': '#7f8c8d',
	'14': '#16a085',
	'15': '#bdc3c7',
	'16': '#f39c12',
	'17': '#1abc9c',
	'18': '#d35400',
	'19': '#18bc9b',
	'20': '#fab005',
	'21': '#1dd7b5',
	'22': '#2c9fca',
	'23': '#e91e63',
	'24': '#c0392b',
	'25': '#27ae60',
	'26': '#f1c40f',
	'27': '#e67e22',
	'28': '#9b59b6',
	'29': '#2ecc71',
};

export const CalendarScheduler: React.FC<Props> = ({ developmentId, calendarId }) => {
	const [selected, setSelected] = useState(new Date());

	const { urlEndpoint } = useRecoilValue(endpointState);

	const [events, setEvents, addEvent] = useArrayState(null);

	let cogo: any = null;
	const fetchAllSchedules = async () => {
		const token = localStorage.getItem('token');

		try {
			if (cogo) {
				(cogo as any).hide();
			}
			cogo = cogoToast.loading(`Buscando agendamentos...`);

			const params: any = {
				development_id: developmentId,
			};

			if (developmentId === 'all-developments' || !developmentId) {
				delete params.development_id;
			}

			if(calendarId) {
				params.calendar_id = calendarId;
			}

			const { data } = await axios.get(`${urlEndpoint}/bookings`, {
				params,
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});

			const categoryCalendars = data.data.reduce(
				(acc: any[], item: any) => {
					if (!acc.find(c => c.id === item.calendar.id)) {
						acc.push({
							id: item.calendar.id,
							name: item.calendar.name,
							index: acc.length,
						});
					}
					return acc;
				},
				[],
			);

			const keyValueCategoryIndex = categoryCalendars.reduce(
				(acc: any, item: any) => {
					acc[item.id] = item.index;
					return acc;
				},
				{},
			);

			const allEvents: [SchedulerExistingEvent] = data.data.map(
				(event: any) => {
					const categoryIndex =
						keyValueCategoryIndex[event.calendar.id];
					const color = colors[categoryIndex] || '#95a5a6';
					const item: SchedulerExistingEvent = {
						is_current: false,
						repeat: 0,
						from: new Date(`${event.date}T${event.start_time}`),
						to: new Date(`${event.date}T${event.end_time}`),
						// name: `${event.calendar.name} - ${event.user.name}`,
						name: event.user.name,
						// calendar: {
						// 	name: event.user.name,
						// 	enabled: true,
						// },
						calendar: {
							name: event.calendar.name,
							enabled: true,
						},
						style: {
							backgroundColor: color,
							color: color === '#95a5a6' ? 'black' : 'white',
						},
					};
					return item;
				},
			);

			// const allEvents: [SchedulerExistingEvent] = data.data.map(
			// 	(event: any) => {
			// 		const color = colors[event.calendar.id] || '#95a5a6';
			// 		const item: SchedulerExistingEvent = {
			// 			is_current: false,
			// 			repeat: 0,
			// 			from: new Date(`${event.date}T${event.start_time}`),
			// 			to: new Date(`${event.date}T${event.end_time}`),
			// 			name: `${event.calendar.name} - ${event.user.name}`,
			// 			calendar: {
			// 				name: event.calendar.name,
			// 				enabled: true,
			// 			},
			// 			style: {
			// 				backgroundColor: color,
			// 				color: color === '#95a5a6' ? 'black' : 'white',
			// 			},
			// 		};

			// 		return item;
			// 	},
			// );

			// const allEvents = data.data.map((event: any) => {
			// 	return {
			// 		is_current: false,
			// 		repeat: 0,
			// 		from: new Date(`${event.date}T${event.start_time}`),
			// 		to: new Date(`${event.date}T${event.end_time}`),
			// 		// name: `${event.calendar.name} - ${event.user.name}`,
			// 		calendar: {
			// 			name: event.calendar.name,
			// 			enable: true,
			// 		},
			// 	};
			// });
			setEvents(allEvents);
		} catch (error) {
			console.log('Error on fetching schedules', error);
			if (cogo) {
				(cogo as any).hide();
			}
		}
	};

	useEffect(() => {
		fetchAllSchedules();
	}, [developmentId, calendarId]);

	return (
		<>
			{/* <Calendar selected={selected} setSelected={setSelected} /> */}
			<Scheduler
				events={events}
				selected={selected}
				setSelected={setSelected}
				onRequestAdd={(evt: any) => {
					console.log(evt);
				}}
				onRequestEdit={(evt: any) =>
					cogoToast.info(
						`${evt.name}\n Em ${moment(evt.from).format(
							'DD/MM/YYYY HH:mm',
						)} - ${moment(evt.to).format('DD/MM/YYYY HH:mm')}`,
					)
				}
				editable={false}
			/>
		</>
	);
};
