export type typeSubMenus = {
	[key: string]: {
		label: string;
		labelSingular: string;
		link: string;
		defaultRouteNewRegister: string;
		defaultRouteUpdateRegister: string;
	};
};

export const allSubMenusRegistrations: typeSubMenus = {
	administrator: {
		label: 'Administradores',
		labelSingular: 'Administrador',
		link: '/registrations/administrators',
		defaultRouteNewRegister: '/new-register/administrador',
		defaultRouteUpdateRegister: '/update/administrador/',
	},
	development: {
		label: 'Empreendimentos',
		labelSingular: 'Empreendimento',
		link: '/registrations/enterprises',
		defaultRouteNewRegister: '/new-register/empreendimento',
		defaultRouteUpdateRegister: '/update/empreendimento/',
	},
	shopkeeper: {
		label: 'Lojistas',
		labelSingular: 'Lojista',
		link: '/registrations/shopkeepers',
		defaultRouteNewRegister: '/new-register/lojista',
		defaultRouteUpdateRegister: '/update/lojista/',
	},
	resident: {
		label: 'Proprietários',
		labelSingular: 'Proprietário',
		link: '/registrations/residents',
		defaultRouteNewRegister: '/new-register/morador',
		defaultRouteUpdateRegister: '/update/morador/',
	},
	manager: {
		label: 'Gestores',
		labelSingular: 'Gestor',
		link: '/registrations/managers',
		defaultRouteNewRegister: '/new-register/gestor',
		defaultRouteUpdateRegister: '/update/gestor/',
	},
	porter: {
		label: 'Porteiros',
		labelSingular: 'Porteiro',
		link: '/registrations/porters',
		defaultRouteNewRegister: '/new-register/porteiro',
		defaultRouteUpdateRegister: '/update/porteiro/',
	},
};

export const subMenusRegistrations: typeSubMenus = {};

export const subMenusPublicacoes: typeSubMenus = {
	'/publicacoes': {
		label: 'Publicações',
		labelSingular: 'Publicação',
		link: '/publicacoes',
		defaultRouteNewRegister: '/new-register/publicacao',
		defaultRouteUpdateRegister: '/update/publicacao/',
	},
	'/publicacoes/categorias': {
		label: 'Categorias',
		labelSingular: 'Categoria',
		link: '/publicacoes/categorias',
		defaultRouteNewRegister: '/new-register/categoria',
		defaultRouteUpdateRegister: '/update/categoria/',
	},
	shortcuts: {
		label: 'Facilidades',
		labelSingular: 'Facilidades',
		link: '/shortcuts',
		defaultRouteNewRegister: '/new-register/shortcuts',
		defaultRouteUpdateRegister: '/update/shortcuts/',
	},
	'/enquetes': {
		label: 'Enquetes',
		labelSingular: 'Enquete',
		link: '/enquetes',
		defaultRouteNewRegister: '/new-register/enquete',
		defaultRouteUpdateRegister: '/update/enquete/',
	},
};

export const subMenusReservas: typeSubMenus = {
	'/reservas': {
		label: 'Reservas',
		labelSingular: 'Reservas',
		link: '/reservas',
		defaultRouteNewRegister: '/new-register/reservas',
		defaultRouteUpdateRegister: '/update/reservas/',
	},
	'/common-areas': {
		label: 'Areas Comuns',
		labelSingular: 'Area Comum',
		link: '/common-areas',
		defaultRouteNewRegister: '/new-register/common-area',
		defaultRouteUpdateRegister: '/update/common-area/',
	},
};

export const subMenusVouchers: typeSubMenus = {
	'/descontos/vouchers': {
		label: 'Descontos',
		labelSingular: 'Desconto',
		link: '/descontos/vouchers',
		defaultRouteNewRegister: '/new-register/desconto',
		defaultRouteUpdateRegister: '/update/desconto/',
	},
};

export const subMenusShopkeeperCategorias: typeSubMenus = {
	'/descontos/categorias': {
		label: 'Categorias Lojistas',
		labelSingular: 'Categoria Lojista',
		link: '/descontos/categorias',
		defaultRouteNewRegister: '/new-register/desconto/categoria',
		defaultRouteUpdateRegister: '/update/desconto/categoria/',
	},
};

export const subMenusSolicitations: typeSubMenus = {
	'/fcg/solicitations': {
		label: 'Solicitações',
		labelSingular: 'Solicitação',
		link: '/fcg/solicitations',
		defaultRouteNewRegister: '/new-register/fcg/solicitations',
		defaultRouteUpdateRegister: '/update/fcg/solicitations/',
	},
	'/chat': {
		label: 'Chats',
		labelSingular: 'Chat',
		link: '/chat',
		defaultRouteNewRegister: '/new-register/chat',
		defaultRouteUpdateRegister: '/update/chat/',
	},
};
