import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import BitButton from '../BitButton/BitButton';
import BitInput from '../BitInput/BitInput';

import { endpointState, filterState } from '../../recoil/atoms';

function isTelefoneSP(value: string) {
	const returnFunction =
		value.match(
			/\([0-9][0-9]\) [0-9](?: [0-9])?[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/,
		) !== null;
	return returnFunction;
}

type BitFiltersProps = {
	labelPesquisa?: string;
};

const BitFilters: React.FC<BitFiltersProps> = ({ labelPesquisa }) => {
	type typeCamposFiltrosPagina = {
		[key: string]: string[];
	};

	const defaultLabelPesquisa = 'Filtros avançados de pesquisa';

	const pathName = useLocation().pathname;

	const [cpf, setCpf] = useState('');
	const [cnpj, setCnpj] = useState('');
	const [nome, setNome] = useState('');
	const [descricao, setDescricao] = useState('');
	const [razao, setRazao] = useState('');
	const [titulo, setTitulo] = useState('');
	const [cidade, setCidade] = useState('');
	const [telefone, setTelefone] = useState('');
	const [empreendimento, setEmpreendimento] = useState('');
	const [areaComum, setAreaComum] = useState('');
	const [tags, setTags] = useState('');
	const [dataCadastro, setDataCadastro] = useState('');
	const [cupom, setCupom] = useState('');
	const [statusSolicitacoes, setStatusSolicitacoes] = useState('');

	type typeResponseAxios = {
		[key: string]: any;
	};

	const { urlEndpoint } = useRecoilValue(endpointState);
	const urlEndpointGetEmpreendimentos = `${urlEndpoint}/developments`;
	const token = localStorage.getItem('token');
	const typeUser = localStorage.getItem('type');
	interface interfaceEmpreendimentos {
		value: {
			value: string | number;
			label: string;
			selected: boolean;
		}[];
		required: boolean;
	}

	const [
		empreendimentos,
		setEmpreendimentos,
	] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});

	const [areasComuns, setAreasComuns] = useState<interfaceEmpreendimentos>({
		value: [],
		required: true,
	});

	const [maskTelephone, setmaskTelephone] = useState([
		'(',
		/\d/,
		/\d/,
		')',
		' ',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		'-',
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	]);

	const keyDownTelefone = function (e: any) {
		if (!(e.which === 9 || e.which === 13)) {
			if (isTelefoneSP(e.currentTarget.value)) {
				setmaskTelephone([
					'(',
					/\d/,
					/\d/,
					')',
					' ',
					/\d/,
					' ',
					/\d/,
					/\d/,
					/\d/,
					/\d/,
					'-',
					/\d/,
					/\d/,
					/\d/,
					/\d/,
				]);
			} else {
				setmaskTelephone([
					'(',
					/\d/,
					/\d/,
					')',
					' ',
					/\d/,
					/\d/,
					/\d/,
					/\d/,
					'-',
					/\d/,
					/\d/,
					/\d/,
					/\d/,
				]);
			}
		}
	};

	const camposFilter = [
		{
			label: 'CPF',
			type: 'maskedinput',
			mask: [
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
			],
			value: cpf,
			keyDown: null,
			onChangeFunction: setCpf,
		},
		{
			label: 'CNPJ',
			type: 'maskedinput',
			mask: [
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'/',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
			],
			value: cnpj,
			keyDown: null,
			onChangeFunction: setCnpj,
		},
		{
			label: 'Nome',
			type: 'text',
			mask: null,
			value: nome,
			keyDown: null,
			onChangeFunction: setNome,
		},
		{
			label: 'Cupom',
			type: 'text',
			mask: null,
			value: cupom,
			keyDown: null,
			onChangeFunction: setCupom,
		},
		{
			label: 'Título',
			type: 'text',
			mask: null,
			value: titulo,
			keyDown: null,
			onChangeFunction: setTitulo,
		},
		{
			label: 'Cidade',
			type: 'text',
			mask: null,
			value: cidade,
			keyDown: null,
			onChangeFunction: setCidade,
		},
		{
			label: 'Telefone',
			type: 'maskedinput',
			mask: maskTelephone,
			value: telefone,
			keyDown: keyDownTelefone,
			onChangeFunction: setTelefone,
		},
		{
			label: 'Empreendimento',
			type: 'select',
			mask: null,
			value: empreendimentos,
			keyDown: null,
			onChangeFunction: setEmpreendimentos,
		},
		{
			label: 'Status Solicitação',
			type: 'statusSolicitacao',
			mask: null,
			value: statusSolicitacoes,
			keyDown: null,
			onChangeFunction: setStatusSolicitacoes,
			valuesSelect: {
				value: [
					{
						value: 'Finalizada',
						label: 'Finalizada',
						selected: false,
					},
					{
						value: 'Em Andamento',
						label: 'Em Andamento',
						selected: false,
					},
					{
						value: 'Pendente',
						label: 'Pendente',
						selected: false,
					},
				],
				required: true,
			},
		},
		{
			label: 'Área Comum',
			type: 'selectCommonAreas',
			mask: null,
			value: areasComuns,
			keyDown: null,
			onChangeFunction: setAreasComuns,
		},
		{
			label: 'Tags',
			type: 'text',
			mask: null,
			value: tags,
			keyDown: null,
			onChangeFunction: setTags,
		},
		{
			label: 'Solicitante',
			type: 'text',
			mask: null,
			value: nome,
			keyDown: null,
			onChangeFunction: setNome,
		},
		{
			label: 'Descrição',
			type: 'text',
			mask: null,
			value: descricao,
			keyDown: null,
			onChangeFunction: setDescricao,
		},
		{
			label: 'Razão',
			type: 'text',
			mask: null,
			value: nome,
			keyDown: null,
			onChangeFunction: setNome,
		},
		{
			label: 'Data de Cadastro',
			type: 'date',
			// mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
			value: dataCadastro,
			keyDown: null,
			onChangeFunction: setDataCadastro,
		},
	];

	const camposFiltrosPagina: typeCamposFiltrosPagina = {
		'/registrations': ['Nome', 'Telefone', 'CPF', 'Data de Cadastro'],
		'/registrations/administrators': [
			'Nome',
			'Telefone',
			'CPF',
			'Data de Cadastro',
		],
		'/registrations/enterprises': ['Nome', 'Cidade', 'Data de Cadastro'],
		'/registrations/shopkeepers': [
			'CNPJ',
			'Nome',
			'Cidade',
			'Data de Cadastro',
		],
		'/registrations/residents': [
			'CPF',
			'Nome',
			'Empreendimento',
			'Data de Cadastro',
		],
		'/registrations/managers': [
			'CPF',
			'Nome',
			'Telefone',
			'Data de Cadastro',
		],
		'/registrations/porters': [
			'CPF',
			'Nome',
			'Telefone',
			'Data de Cadastro',
		],
		'/reservas': ['Área Comum', 'Empreendimento'],
		'/common-areas': ['Empreendimento'],
		'/fcg/solicitations': [
			'Solicitante',
			'Status Solicitação',
			'Data de Cadastro',
		],
		'/denuncias': ['Solicitante', 'Data de Cadastro'],
		'/denuncias/razoes': ['Razão'],
		'/denuncias/filtros': [],
		'/denuncias/filtrados': [
			'Título',
			'Empreendimento',
			'Data de Cadastro',
		],
		'/publicacoes': ['Título', 'Empreendimento', 'Data de Cadastro'],
		'/publicacoes/categorias': [
			'Nome',
			'Tags',
			'Empreendimento',
			'Data de Cadastro',
		],
		'/shortcuts': ['Nome', 'Link', 'Telefone', 'Data de Cadastro'],
		'/enquetes': ['Título', 'Empreendimento'],
		'/descontos/vouchers': ['Nome', 'Título', 'Data de Cadastro'],
		'/descontos/categorias': ['Nome', 'Tags', 'Data de Cadastro'],
		'/notificacao/lista-de-push': [
			'Título',
			'Empreendimento',
			'Data de Cadastro',
		],
		'/packages': ['Nome', 'Empreendimento', 'Data de Cadastro'],
	};

	const camposFiltrosPaginaAtual = camposFiltrosPagina[pathName];

	const camposFilterExibir = [camposFilter[0]];

	camposFilterExibir.pop();

	camposFilter.map((valueFiltros, index) => {
		if (camposFiltrosPaginaAtual?.includes(valueFiltros.label)) {
			camposFilterExibir.push(valueFiltros);
		}
		return null;
	});

	const setFilterState = useSetRecoilState(filterState);

	const handleLimpar = (e: React.FormEvent<HTMLInputElement>) => {
		e.preventDefault();

		setCpf('');
		setCnpj('');
		setNome('');
		setTitulo('');
		setCidade('');
		setTelefone('');
		setTags('');
		setEmpreendimento('');
		setAreaComum('');
		setDataCadastro('');
		setCupom('');
		setStatusSolicitacoes('');
		setAreaComum('');

		setFilterState({
			cpf: '',
			cnpj: '',
			nome: '',
			titulo: '',
			cidade: '',
			telefone: '',
			empreendimento: '',
			areaComum: '',
			tags: '',
			dataCadastro: '',
			cupom: '',
			statusSolicitacoes: '',
		});
	};

	const handleFiltrar = (e?: React.FormEvent<HTMLInputElement>) => {
		e?.preventDefault();

		setFilterState({
			cpf,
			cnpj,
			nome,
			titulo,
			cidade,
			telefone,
			empreendimento,
			areaComum,
			tags,
			dataCadastro,
			cupom,
			statusSolicitacoes,
		});
	};

	useEffect(() => {
		setCpf('');
		setCnpj('');
		setNome('');
		setTitulo('');
		setTags('');
		setCidade('');
		setTelefone('');
		setEmpreendimento('');
		setAreaComum('');
		setDataCadastro('');
		setCupom('');
		setStatusSolicitacoes('');

		setFilterState({
			cpf: '',
			cnpj: '',
			nome: '',
			titulo: '',
			cidade: '',
			telefone: '',
			empreendimento: '',
			areaComum: '',
			tags: '',
			dataCadastro: '',
			cupom: '',
			statusSolicitacoes: '',
		});
	}, [pathName]);

	const fetchCommonAreas = async (development: string | null) => {
		const { data } = await axios.get(`${urlEndpoint}/calendars`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: {
				development: development || empreendimento,
			},
		});

		const allCalendars = data.data.data;

		const newValueCalendars: {
			value: string;
			label: string;
			selected: boolean;
		}[] = allCalendars.map((calendar: any) => {
			return {
				value: calendar.id,
				label: calendar.name,
				selected: false,
			};
		});

		setAreasComuns({
			value: newValueCalendars,
			required: false,
		});

		if(newValueCalendars.length === 1) {
			setAreaComum(newValueCalendars[0].value);
		}
	};

	useEffect(() => {
		axios
			.get(urlEndpointGetEmpreendimentos, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response: typeResponseAxios) {
				const allEmpreendimentos = response.data.data.data;

				const newValueEmpreendimentos: {
					value: string | number;
					label: string;
					selected: boolean;
				}[] = [];

				if (typeUser === 'Porteiro') {
					newValueEmpreendimentos.push({
						value: allEmpreendimentos.id,
						label: allEmpreendimentos.name,
						selected: true,
					});
				} else {
					allEmpreendimentos.map((empr: any) => {
						const isSelected = false;

						newValueEmpreendimentos.push({
							value: empr.id,
							label: empr.name,
							selected: isSelected,
						});
						return null;
					});
				}

				if (newValueEmpreendimentos.length === 1) {
					setEmpreendimento(`${newValueEmpreendimentos[0].value}`);
					handleFiltrar();
				}

				setEmpreendimentos({
					value: newValueEmpreendimentos,
					required: empreendimentos.required,
				});

				if (pathName === '/reservas') {
					fetchCommonAreas(
						newValueEmpreendimentos.length === 1
							? `${newValueEmpreendimentos[0].value}`
							: null,
					);
				}
			});
	}, []);

	return (
		<div className="bloco-pesquisa-filtros">
			<div className="p-5 bg-gray rounded-t color-black font-medium cabecalho-pesquisa-filtros">
				<i className="fas fa-chevron-down mr-2" />
				{labelPesquisa ?? defaultLabelPesquisa}
			</div>
			<div className="p-5 mb-8 bg-white rounded-b">
				<div className="flex -ml-2 -mr-2">
					{camposFilterExibir.map(campo => {
						return (
							<div className="w-4/12 ml-2 mr-2">
								{campo.type === 'select' ? 
								(
									<BitInput
										label="Selecione o empreendimento"
										type="select"
										opcaoTodosSelect={false}
										multipleSelect={false}
										placeholder=""
										valuesSelect={empreendimentos.value}
										classField=""
										onChange={(
											e: React.FormEvent<HTMLSelectElement>,
										) => {
											const allOptions = Object.entries(
												e.currentTarget.options,
											);

											allOptions.map(option => {
												if (option[1].selected) {
													setEmpreendimento(
														option[1].value,
													);
												}
												return null;
											});
										}}
									/>
								) : campo.type === 'selectCommonAreas' ? (
									<BitInput
										label="Área Comun"
										type="select"
										opcaoTodosSelect={false}
										multipleSelect={false}
										placeholder=""
										valuesSelect={areasComuns.value}
										classField=""
										onChange={(
											e: React.FormEvent<HTMLSelectElement>,
										) => {
											const allOptions = Object.entries(
												e.currentTarget.options,
											);

											allOptions.map(option => {
												if (option[1].selected) {
													setAreaComum(
														option[1].value,
													);
												}
												return null;
											});
										}}
									/>
								) : campo.type === 'statusSolicitacao' ? (
									<BitInput
										label="Status"
										type="select"
										opcaoTodosSelect={false}
										multipleSelect={false}
										placeholder=""
										valuesSelect={
											(campo.valuesSelect
												?.value as unknown) as {
												label: string;
												value: number | string;
												selected: boolean;
											}[]
										}
										classField=""
										onChange={(
											e: React.FormEvent<HTMLSelectElement>,
										) => {
											const allOptions = Object.entries(
												e.currentTarget.options,
											);

											allOptions.map(option => {
												if (option[1].selected) {
													setStatusSolicitacoes(
														option[1].value,
													);
												}
												return null;
											});
										}}
									/>
								) : (
									<BitInput
										label={campo.label}
										type={campo.type}
										mask={campo.mask}
										placeholder="Digite..."
										onChange={(
											e: React.FormEvent<HTMLInputElement>,
										) =>
											campo.onChangeFunction(
												// @ts-ignore
												e.currentTarget.value,
											)
										}
										onKeyPress={(e: any) => {
											if (e.key === 'Enter') {
												handleFiltrar(e);
											}
										}}
										onKeyDown={campo.keyDown}
										// @ts-ignore
										value={campo.value}
									/>
								)}
							</div>
						);
					})}
				</div>
				<div className="text-right">
					<BitButton
						text={'Limpar filtros'}
						buttonColor={'#FFFFFF'}
						textColor={'#000000'}
						type={'fit px-10'}
						onClick={(e: React.FormEvent<HTMLInputElement>) =>
							handleLimpar(e)
						}
						classesBlockOption={'inline-block mr-2'}
					/>

					<BitButton
						text={'Filtrar'}
						buttonColor={'#3AABA2'}
						textColor={'#FFFFFF'}
						type={'fit px-10'}
						onClick={(e: React.FormEvent<HTMLInputElement>) =>
							handleFiltrar(e)
						}
						classesBlockOption={'inline-block'}
					/>
				</div>
			</div>
		</div>
	);
};

export default BitFilters;
